import React from "react"
import BackgroundImage from "gatsby-background-image"
import "./HeadImage.css"

import { Row, Col } from "react-bootstrap"

const switchToWebP = imageData => {
  let convertedImageData = { ...imageData }

  if (convertedImageData.src && convertedImageData.srcWebp) {
    convertedImageData.srcDefault = convertedImageData.src
    convertedImageData.src = convertedImageData.srcWebp
  }
  if (convertedImageData.srcSet && convertedImageData.srcSetWebp) {
    convertedImageData.srcSetDefault = convertedImageData.srcSet
    convertedImageData.srcSet = convertedImageData.srcSetWebp
  }

  return convertedImageData
}

const HeadImage = ({ image, height, black, children, news }) => {
  const classes = black ? ["image", "w-100"] : ["image", "black", "w-100"]
  if (news) classes.push("news")
  const containerStyle = {}
  if (height !== "auto") containerStyle["minHeight"] = height + "px"
  return (
    <BackgroundImage
      Tag="section"
      fluid={switchToWebP(image)}
      className={classes.join(" ")}
      style={containerStyle}
    >
      {black ? (
        <div className="overlay black"></div>
      ) : (
        <div className="overlay white"></div>
      )}
      <Row className="h-100 justify-content-center align-items-center mx-0">
        <Col xs={12} sm={11} md={10} lg={8} className="text-center">
          {children}
        </Col>
      </Row>
    </BackgroundImage>
  )
}
export default HeadImage
